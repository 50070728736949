<template>
  <v-data-table
    :key="sDataTableKey"
    v-model="arCurrencyIdList"
    :headers="headers"
    :items="ready ? items : []"
    :loading="loading"
    class="dt-listing-simple"
    disable-sort
    show-select
    @item-selected="onToggle"
  >
    <template #[`header.data-table-select`]>
      <span />
    </template>

    <template #[`item.name`]="{ item }">
      <currency-preview v-if="item.rate_value === 1" :item="item" />
      <currency-rate-history-dialog v-else :currency="item.code" />
    </template>

    <template #[`item.coderya`]="{ item }">
      <company-currency-rya-code
        v-if="!!obCompany.id"
        :company-id="obCompany.id"
        :value="item"
      />
    </template>
    <!--    <template #[`item.code_account`]="{ item }">
          <company-currency-account-code
            :value="item"
            :company-id="obCompany.id"
            :disabled="!ready"
          />
        </template>-->
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, VModel } from "vue-property-decorator";
import {
  Company,
  CurrencyRate,
  CurrencyRates,
  InvoiceTypeData,
} from "@planetadeleste/vue-mc-gw";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import { EventBus } from "@/services/event-bus";
import {
  CurrencyCollection,
  CurrencyData,
} from "@planetadeleste/vue-mc-shopaholic";
import { AppModule } from "@/store/app";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import { filter, find, map, set } from "lodash";
import { ConfigModule } from "@/store/config";
import useInvoice from "@/composables/invoice";
import { number } from "mathjs";
import CompanyCurrencyRyaCode from "@/modules/companies/components/CompanyCurrencyRyaCode.vue";
import CurrencyRateHistoryDialog from "@/modules/currencyrates/components/CurrencyRateHistoryDialog.vue";

type DataTableSelectRow = {
  item: CurrencyData;
  value: boolean;
};

@Component({
  components: {
    CurrencyRateHistoryDialog,
    CompanyCurrencyRyaCode,
    CurrencyPreview,
  },
})
export default class Currencies extends Mixins(DataTableMixin) {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;

  obCurrencies: CurrencyRates = new CurrencyRates();
  arCurrencyIdList: CurrencyData[] = [];
  arUsedCurrencyIdList: number[] = [];
  loading = false;
  ready = false;
  headers: DataTableHeader[] = [
    { text: "name", value: "name", sortable: false },
    { text: "code", value: "code", sortable: false },
    { text: "symbol", value: "symbol", sortable: false },
    { text: "bcu.rate", value: "rate", sortable: false },
    // { text: "account.code", value: "code_account", sortable: false },
  ];
  arInvoiceTypeList: InvoiceTypeData[] = [];

  get currencies(): CurrencyCollection {
    return AppModule.currencies;
  }

  get items(): CurrencyData[] {
    const arCurrencyList = filter(
      this.currencies.getModelList(),
      (obItem) => !!obItem.id
    );
    return map(arCurrencyList as CurrencyData[], (obItem) => {
      let isSelectable =
        !obItem.is_default &&
        (!this.arUsedCurrencyIdList.includes(obItem.id) ||
          !find(this.arCurrencyIdList, { id: obItem.id }));

      if (this.hasReceiptInvoiceType) {
        const obCurrencyRate = this.obCurrencies.find({
          currency_id: obItem.id,
        });
        const sCodeRya = obCurrencyRate ? obCurrencyRate.get("coderya") : null;
        set(obItem, "coderya", sCodeRya);

        // Override selectable to false currency has invoices on erecibos db
        if (obCurrencyRate && !obCurrencyRate.get("editable", false)) {
          isSelectable = false;
        }
      }

      set(obItem, "isSelectable", isSelectable);

      return obItem;
    });
  }

  get hasReceiptInvoiceType(): boolean {
    return (
      !!this.obCompany &&
      !!this.obCompany.id &&
      filter(this.arInvoiceTypeList, (obModel) => number(obModel.code) === 701)
        .length > 0
    );
  }

  mounted() {
    this.load().then(() => {
      if (this.hasReceiptInvoiceType) {
        const obHeaderItem: DataTableHeader = {
          text: "currency.rya.code",
          value: "coderya",
          sortable: false,
          width: 200,
        };
        this.headers.splice(1, 0, obHeaderItem);
      }
      this.mapDTHeaders();
    });
  }

  async load(): Promise<void> {
    if (!this.obCompany.id) {
      return;
    }

    this.loading = true;

    await this.loadCompanyInvoiceTypes();

    const obCurrencyRateCollection = new CurrencyRates();
    // @ts-ignore
    await obCurrencyRateCollection
      .filterBy({ companyGrouped: this.obCompany.id })
      .fetch();
    this.obCurrencies = obCurrencyRateCollection;
    this.arCurrencyIdList = filter(
      this.items,
      (obModel) => !!obCurrencyRateCollection.find({ currency_id: obModel.id })
    );
    EventBus.emit("get.company.currencies", this.obCurrencies);

    // Load used currencies by company
    this.arUsedCurrencyIdList = await useInvoice().listByType<number[]>(
      "currency_id",
      this.obCompany.id
    );

    this.loading = false;
    this.ready = true;
  }

  async onToggle(obValue: DataTableSelectRow) {
    const bSelected = obValue.value;
    const obItem = obValue.item;
    const obData = {
      currency_id: obItem.id,
      company_id: this.obCompany.id,
    };
    let bRes: boolean | undefined = true;

    // If item is removed, ask before continue
    if (!bSelected) {
      const sMessage = this.$t("ask.remove.company.currency") as string;
      bRes = await this.$confirm(sMessage, { color: "warning" });
    }

    this.loading = true;
    const obModel = new CurrencyRate();

    // If response is false, revert unselected item
    if (!bSelected) {
      if (!bRes) {
        this.arCurrencyIdList.push(obItem);
      } else {
        await this.obCompany.currencyRemove(obItem.id);
        // const obConfig: AxiosRequestConfig = {
        //   url: route("companies.currency.destroy", obData),
        //   method: "DELETE",
        //   data: obData,
        // };
        // await obModel.createRequest(obConfig).send();
      }
    } else {
      obModel.set(obData);
      obModel.sync();
      await obModel.store();
    }

    if (this.obCompany.id === AppModule.company.id) {
      await ConfigModule.loadCompanyCurrencyRates(true);
    }

    this.loading = false;
  }

  async loadCompanyInvoiceTypes() {
    if (
      !this.obCompany ||
      !this.obCompany.id ||
      this.arInvoiceTypeList.length
    ) {
      return;
    }

    const obResponse = await this.obCompany.getInvoiceTypes();
    this.arInvoiceTypeList = obResponse.getData().data;
  }
}
</script>
